import Vue from "vue";
// 引入路由
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 创建路由器
export default new VueRouter({
  // mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      component: () => import("@/views/login/index.vue"),
      name: "Login",
    },
    // 可视化大屏
    {
      path: "/screen",
      name: "Screen",
      component: () => import("@/views/screen/index.vue"),
      meta: {
        title: "可视化大屏",
        hidden: false,
        icon: "",
      },
    },
    // 主页内容
    {
      path: "/home",
      component: () => import("@/views/home/index.vue"),
      name: "Home",
      meta: {
        title: "主页", // 菜单标题
        hidden: false,
        icon: "",
      },
      redirect: "/realTimeData",
      children: [
        {
          path: "/realTimeData",
          component: () => import("@/views/realTimeData/index.vue"),
          name: "realTimeData",
          // meta: {
          //   title: "实时数据", // 菜单标题
          //   hidden: false,
          //   icon: "",
          // },
        },
        {
          path: "/history",
          component: () => import("@/views/history/index.vue"),
          name: "history",
        },
        {
          path: "/laboratory",
          component: () => import("@/views/laboratory/index.vue"),
          name: "laboratory",
        },
        {
          path: "/system",
          component: () => import("@/views/system/index.vue"),
          name: "system",
        },

        // 账户状态
        {
          path: "/loginStatus",
          component: () => import("@/views/loginStatus/index.vue"),
          name: "loginStatus",
        },

        // 我的
        {
          path: "/myPage",
          component: () => import("@/views/myPage/index.vue"),
          name: "myPage",
        },
      ],
    },
  ],
});
